const InputFieldType = {
  EMAIL: "email",
  LOOKUP: "lookup",
  LOOKUP_UOM: "lookupuom",
  DATE: "date",
  NUMBER: "number",
  INTEGER: "integer",
  BOOLEAN: "boolean",
  STRING: "string",
  UOM: "uom",
  OBJECT_TYPES: "types:object",
  PROJECT_TYPES: "types:project",
  EQUIPMENT_TYPES: "types:equipment",
  SIGNAL_TYPES: "type:signal_type",
  CABLE_TYPES: "type:cable_type",
  CABLE_BUNDLES_TYPES: "type:cable_bundle",
  DRUM_TYPES: "type:drum",
  ORDER_TYPES: "type:order",
  OBJECTS: "objects_list",
  OBJECT_NUMBERS: "object_id_list",
  SYSTEM_GROUP_NUMBER_LIST: "system_group_id_list",
  SYSTEM_NUMBER_LIST: "system_id_list",
  SECTIONS_LIST: "section_id_list",
  SECTION_REFERENCE: "section_reference",
  EQUIPMENT_LIST: "equipment_id_list",
  LOCATION_LIST: "location_id_list",
  PROJECTS_LIST: "project_id_list",
  CABLE_TYPE_LIST: "cable_type_id_list",
  CABLE_BUNDLE_LIST: "cable_bundle_id_list",
  DRUM_LIST: "drum_id_list",
  ORDER_LIST: "order_id_list",
  // Fallback value
  DEFAULT: "default",
};

export default InputFieldType;
