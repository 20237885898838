import compose from "compose-function";
import { del, patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const CABLES_API_PATH = "/cables";

/**
 * Create a cable
 *
 * @param {object[]} cable
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233162/ }
 */
export async function addCable(cable) {
  const body = await post(CABLES_API_PATH, cable);
  return body?.response;
}

/**
 * Update a cable
 *
 * @param {object[]} cable
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233427/}
 */
export async function patchCable(cable, cableId, isInlineEdit) {
  return patch(
    [CABLES_API_PATH, cableId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("source_destination"),
      sanitizeEntity("object_number"),
      sanitizeEntity("project_reference"),
      sanitizeEntity("system_group_number"),
      sanitizeEntity("system_number"),
      sanitizeEntity("source_section_location"),
      sanitizeEntity("destination_section_location"),
      sanitizeEntity("is_deleted")
    )(cable)
  );
}

/**
 * Mark cable as deleted
 *
 * @param {object[]} cable
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233427/}
 */
export async function softDeleteCable(cable) {
  const body = await patch([CABLES_API_PATH, cable.id].join("/"), {
    delete: true,
  });
  return body.response;
}

/**
 * Mark cable as restored
 *
 * @param {object[]} cable
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233427/}
 */
export async function restoreCable(cable) {
  const body = await patch([CABLES_API_PATH, cable.id].join("/"), {
    delete: false,
  });
  return body.response;
}

/**
 * Remove cable from database
 *
 * @param {object[]} cable
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233688/}
 */
export async function hardDeleteCable(cable) {
  const body = await del([CABLES_API_PATH, cable.id].join("/"));
  return body.response;
}
