const FieldName = {
  CUSTOMER: 'customer',
  OBJECT_TYPE: 'type',
  OBJECT_ID: 'object_id',
  SYSTEM_GROUP_ID: 'system_group_id',
  SYSTEM_ID: 'system_id',
  LOCATION_ID: 'location_id',
  EQUIPMENT_ID: 'equipment_id',
  SECTION_ID: 'section_id',
  SECTION_REFERENCE: 'section_reference',
  PROJECT_ID: 'project_id',
};

export default FieldName;
