import { useEffect, useState, useCallback } from "react";
import { getEntities } from "../dao/common";
import { errorMessageHandler } from "../utils/error";

const useCalculatedSection = ({ section = null, isUpdateMode = false }) => {
  const [calculatedSection, setCalculatedSection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCalculatedSection = useCallback(
    async (abortController) => {
      // section object is initialized with empty values in useSection hook
      // so we need to check if one of the values is not empty, preferably object_id which is required
      if (!isUpdateMode || !section.object_id) return;

      const queryParams = {
        object_ids: section.object_id,
        filters: JSON.stringify({
          section_id: section.id,
          equipment_reference2: section.equipment_reference2,
          section_number: section.number,
        }),
      };

      try {
        const res = await getEntities(
          "/calculated-sections",
          queryParams,
          abortController
        );
        setCalculatedSection(res.response[0]);
        setIsLoading(false);
      } catch (error) {
        errorMessageHandler(error);
        setIsLoading(false);
      }
    },
    [isUpdateMode, section]
  );

  useEffect(() => {
    const abortController = new AbortController();
    fetchCalculatedSection(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchCalculatedSection]);

  return {
    calculatedSection,
    fetchCalculatedSection,
    isLoading,
  };
};

export default useCalculatedSection;
