import { useState, useEffect } from 'react';
import { getEntities } from '../dao/common';
import FieldName from './../enums/FieldName';
import { errorMessageHandler } from './../utils/error';

/**
 * state initialization function
 * should return an initial state object
 * @returns {object} initial state object
*/
function createInitialState() {
  return {
    object_id: "",
    project_id: "",
    system_group_id: "",
    system_id: "",
  };
}

export default function useEquipment({
    objectId = null,
    systemGroupId = null,
    systemId = null,
    locationId = null,
    equipmentId = null,
    projectId = null,
    sectionReference = null,
}) {
  const [ equipment, setEquipment ] = useState(createInitialState);
  const [ isLoading, setIsLoading ] = useState(true);

  const fetchEquipmentData = (abortController) => {
    if (equipmentId) {
      getEntities(
        '/equipments',
        {
          object_ids: objectId,
          system_ids: systemId,
          ids: equipmentId,
          overview: true,
        },
        abortController,
      ).then((res) => {
        const response = res.response;
        // guard
        if (response.length === 0) {
          return;
        }
        setEquipment(response[0]);
        setIsLoading(false);
      })
      .catch(errorMessageHandler);
    } else {
      if (objectId) {
        setEquipment((prevState) => ({
          ...prevState,
          [FieldName.OBJECT_ID]: objectId,
        }));
      }
      if (systemGroupId) {
        setEquipment((prevState) => ({
          ...prevState,
          [FieldName.SYSTEM_GROUP_ID]: systemGroupId,
        }));
      }
      if (systemId) {
        setEquipment((prevState) => ({
          ...prevState,
          [FieldName.SYSTEM_ID]: systemId,
        }));
      }
      if (locationId) {
        setEquipment((prevState) => ({
          ...prevState,
          [FieldName.LOCATION_ID]: locationId,
        }));
      }
      if (sectionReference) {
        setEquipment((prevState) => ({
          ...prevState,
          [FieldName.SECTION_REFERENCE]: sectionReference,
        }));
      }
      if (projectId) {
        setEquipment((prevState) => ({
          ...prevState,
          [FieldName.PROJECT_ID]: projectId,
        }));
      }
      setIsLoading(false);
    }
  };

  /**
   * useEffect should have a dependency on equipmentId
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchEquipmentData(abortController);
    return () => {
      abortController.abort();
    };
  }, [ equipmentId ]);

  return { 
    equipment,
    fetchEquipmentData,
    isLoading
  };
}
