import { useState, useEffect } from "react";
import { getEntities } from "../dao/common";
import FieldName from "./../enums/FieldName";
import { errorMessageHandler } from "./../utils/error";

/**
 * state initialization function
 * should return an initial state object
 * @returns {object} initial state object
 */
function createInitialState() {
  return {
    object_id: "",
    system_group_id: "",
    system_id: "",
    equipment_id: "",
  };
}

export default function useSection({
  objectId = null,
  systemGroupId = null,
  systemId = null,
  equipmentId = null,
  sectionId = null,
}) {
  const [section, setSection] = useState(createInitialState);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSectionData = (abortController) => {
    if (sectionId) {
      getEntities(
        "/sections",
        {
          object_ids: objectId,
          equipment_ids: equipmentId,
          ids: sectionId,
          overview: true,
        },
        abortController
      )
        .then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setSection(response[0]);
          setIsLoading(false);
        })
        .catch(errorMessageHandler);
    } else {
      if (objectId) {
        setSection((prevState) => ({
          ...prevState,
          [FieldName.OBJECT_ID]: objectId,
        }));
      }
      if (systemGroupId) {
        setSection((prevState) => ({
          ...prevState,
          [FieldName.SYSTEM_GROUP_ID]: systemGroupId,
        }));
      }
      if (systemId) {
        setSection((prevState) => ({
          ...prevState,
          [FieldName.SYSTEM_ID]: systemId,
        }));
      }
      if (equipmentId) {
        setSection((prevState) => ({
          ...prevState,
          [FieldName.EQUIPMENT_ID]: equipmentId,
        }));
      }
      setIsLoading(false);
    }
  };

  /**
   * useEffect should have a dependency on sectionNumber
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchSectionData(abortController);
    return () => {
      abortController.abort();
    };
  }, [sectionId]);

  return {
    section,
    fetchSectionData,
    isLoading,
  };
}
